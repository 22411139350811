.text-center {
  text-align: center !important;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

blockquote,
body,
fieldset,
figure,
menu,
pre,
ol,
ul,
dd,
dl {
  margin: 0;
  padding: 0;
  word-wrap: break-word;
}

ol,
ul,
dd,
dl {
  padding-left: 2rem;
}

h1 {
  font-size: 1.5rem;
}

h2 {
  font-size: 1.5rem;
}

h3 {
  font-size: 1.25rem;
}

h4,
h5,
h6 {
  font-size: 1rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0.5rem 0;
  line-height: 1.5rem;
  font-weight: 500;
}

b, optgroup, strong {
  font-weight: 600;
}

a {
  color: inherit;
}

p {
  margin: 0.75rem 0;
}

a:link,
a:visited,
a:hover,
a:active {
  outline: none;
  text-decoration: none;
}

.pr {
  position: absolute !important;
  right: 0;
}

.float-r {
  float: right;
}

em {
  color: #FF5722;
  font-weight: bold;
  font-style: normal;
}

footer {
  background-color: #fff;
  border-top: 1px solid #eee;
  padding-bottom: 1.5rem!important;
  padding-top: 1.5rem!important;
  margin-top: 1rem!important;
  color: @gray;
  font-size: 12px;
  position: relative;
  z-index: 1024;
}

iframe {
  width: 100%;
  height: 100%;
}

.clearfix:before,
.clearfix:after {
  content: " ";
  display: table;
}

.clearfix:after {
  clear: both;
}


/* 设置滚动条的样式 */

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}


/* 滚动槽 */

::-webkit-scrollbar-track {
  border-radius: 4px;
}


/* 滚动条滑块 */

::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, .1);
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, .3);
}