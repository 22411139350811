html,
body {
  font-size: @font-size;
  font-family: "Fira Code", "Source Han Serif SC", "SimSun", "Microsoft Yahei",
    "Helvetica Neue", "PingFang SC", sans-serif;
}
body {
  color: @m-black;
  line-height: 1.8rem;
  margin: 0;
}
.left {
  text-align: left;
}
.right {
  text-align: right;
}

.home-menu {
  background: transparent;
  height: @nav-height;
  padding: @nav-padding;
  display: none;
  @media screen {
    display: block;
  }
  @media screen and (max-width: @md-width) {
    background: white;
    padding: @nav-small-padding;
  }
  .pure-menu-heading {
    padding: 0;
    position: relative;
  }
  img {
    display: inline-block;
    vertical-align: middle;
  }
  .title {
    color: @m-black;
    margin: 0 5px;
    display: inline-block;
    vertical-align: middle;
    font-family: "思源宋体", "宋体", "黑体";
    font-weight: 700;
    font-size: 25px;
    line-height: 40px;
  }
  .logo {
    cursor: pointer;
    position: absolute;
    left: 50%;
    top: @logo-top;
    margin-left: -16px;
    width: 32px;
    height: 32px;
    padding: 5px;
    @media screen and (max-width: @xl-width) {
      left: auto;
      right: 10px;
    }
  }
  .pure-menu-list {
    .toc-menu {
      display: none;
      @media screen and (min-width: @xl-width) {
        display: initial;
      }
    }
    .menu-icon {
      width: 18px;
      height: 18px;
    }
    .pure-menu-children {
      border: 1px solid @gray-light;
    }
    .pure-menu-link {
      padding: 4px 15px;
      color: inherit;
      &:after {
        content: "";
        display: inline;
        vertical-align: middle;
      }
      &:focus {
        background-color: #fff;
      }
    }
    .pure-menu-item.actived {
      color: @light-blue;
    }
  }
}

.container {
  padding-top: @padding-top;
  margin: 0 auto;
  max-width: 1000px;
  min-height: calc(~"100vh - 120px");

  @media screen and (min-width: @xl-width) {
    max-width: 1200px;
  }
}

.recent-posts {
  margin: 0 auto;
  max-width: @index-width;
  .recent-post-item {
    margin: 10px;
    padding: 10px 0;
    border-top: none;
    .post-title {
      position: relative;
    }

    .post-excerpt {
      color: @gray-light;
      @media screen and (max-width: @sm-width) {
        display: none;
      }
    }
    .post-footer {
      font-size: 14px;
      position: relative;
      padding-right: 60px;
      min-height: 30px;
      a {
        font-weight: 300;
        display: block;
        padding: 2px 6px;
        line-height: 1rem;
      }
    }
    .title {
      line-height: 1.8rem;
      display: block;
      padding-right: 64px;
      h2 {
        font-size: 1.2rem;
        font-weight: 300;
        @media screen and (max-width: @sm-width) {
          font-size: 1rem;
        }
      }
      &:hover {
        color: @light-blue;
      }
    }
    .index-tag-list {
      display: inline;
      padding: 0;
      .index-tag-list-item {
        margin-right: 5px;
        color: @gray-light;
        border-radius: 2px;
        display: inline-block;
        list-style: none;
        border: 1px solid rgba(0, 0, 0, 0.06);
        &:hover {
          background-color: @bg-gray-hover;
        }
      }
    }
    .time {
      top: 0;
      font-size: 14px;
      color: @gray-light;
    }
  }
}

.page-nav {
  margin: 12px 0 0;
  padding: 10px 0 10px;
  .page-number {
    border-bottom: 1px solid #eee;
    display: inline-block;
    position: relative;
    top: -1px;
    margin: 0 10px;
    padding: 0 11px;
    &:hover {
      border-bottom-color: #222;
    }
    &.current {
      color: #fff;
      background: #ccc;
      border-top-color: #ccc;
    }
  }
}

.post {
  .post-page;
  .post-header {
    margin: 10px 0 20px;
    .title {
      margin: 1rem;
      line-height: 2rem;
    }
    .time {
      color: @gray-light;
      font-size: 14px;
    }
  }
  hr {
    width: 10%;
  }
  blockquote {
    padding: 1rem;
    margin: 1rem 0;
    padding-left: 20px;
    border: 1px solid #eee;
    border-left: 4px solid #ccc;
    color: @gray;
    p {
      margin: 0;
    }
  }
  table {
    max-width: 100%;
  }
  img {
    text-align: center;
    margin: 0 auto;
    display: block;
    max-width: 100%;
    max-height: 1024px;
  }
  .post-content {
    h2,
    h3,
    h4,
    h5,
    h6 {
      padding-top: 70px;
      margin-top: -56px;
    }
    table {
      width: 100%;
      max-width: 100%;
      margin-bottom: 21px;
      border: 1px solid #ddd;
      tr {
        border-top: 1px solid #cccccc;
        background-color: white;
        margin: 0;
        padding: 0;
      }
      tr:nth-child(2n) {
        background-color: #f8f8f8;
      }
      th {
        font-weight: bold;
        border: 1px solid #cccccc;
        text-align: left;
        margin: 0;
        padding: 6px 13px;
        background-color: #f6f8fa;
      }
      td {
        border: 1px solid #cccccc;
        text-align: left;
        margin: 0;
        padding: 6px 13px;
      }
      th:first-child,
      td:first-child {
        margin-top: 0;
      }
      th:last-child,
      td:last-child {
        margin-bottom: 0;
      }
    }
    .note {
      position: relative;
      padding: 15px;
      margin-bottom: 20px;
      border: 1px solid #eee;
      border-left-width: 5px;
      p {
        margin: 0;
      }
      &.primary {
        border-left-color: #6f42c1;
      }
      &.info {
        border-left-color: #428bca;
      }
      &.success {
        border-left-color: #5cb85c;
      }
      &.warning {
        border-left-color: #f0ad4e;
      }
      &.danger {
        border-left-color: #d9534f;
      }
    }
    a {
      color: @light-blue;
    }
  }
  .post-tags {
    text-align: center;
    margin: 20px 0;
    .article-tag-list-item {
      .tag-button;
    }
  }
}

.page {
  max-width: @page-width;
  border: none;
  box-shadow: none;
  hr {
    width: 10%;
  }
  li {
    padding: 0.25rem 0;
  }
  .tag-list {
    .tag-list-item {
      .tag-button;
      .tag-list-link {
        font-weight: 500;
        margin-right: 5px;
      }
    }
  }
}

.tag-page {
  .post-page;
  border: none !important;
  box-shadow: none !important;
  .tag-item {
    padding: 0.5rem 0;
    time {
      color: @gray-light;
      font-size: 12px;
      float: right;
      @media screen and (max-width: @sm-width) {
        display: block;
        float: none;
      }
    }
  }
}

.toc-article {
  display: none;
}

.post-nav {
  background-color: #fff;
  display: block;
  display: flex;
  font-size: 0;
  margin: 10px auto;
  max-width: @post-width;
  padding: 0 60px;
  position: relative;
  .post-nav-item {
    display: inline-block;
    flex: 1;
    font-size: 14px;
    padding: 10px 0;
    position: relative;
    vertical-align: top;
    width: 49.5%;

    a {
      display: inline-block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
    }
  }
  .post-nav-prev {
    text-align: right;
  }
  @media screen and (max-width: @sm-width) {
    display: none;
  }
}

.toc-article {
  display: none;
  @media screen and (min-width: @xl-width) {
    max-height: calc(~"100vh - 80px");
    position: fixed;
    top: 60px;
    font-size: 13px;
    width: 200px;
    padding: 10px;
    margin-left: 1050px;
    overflow-y: auto;
    border-radius: 4px;
    color: white;
    background-color: @bg-dark;
  }
  ol,
  ul {
    list-style: none;
    padding-left: 0;
    ol,
    ul {
      padding-left: 1rem;
    }
  }
}

.disqus-comments {
  background-color: #fff;
  display: block;
  margin: 0 auto;
  max-width: @post-width;
  padding: 0 15px;
}

.search-content {
  .pure-input {
    border-radius: 2px !important;
    &:focus {
      border-color: @m-black !important;
    }
  }
  .search-result {
    color: @gray-light;
  }
}

.search-result-list {
  margin-top: 10px;
  a.search-result-title {
    color: @m-black;
    font-weight: normal;
    font-size: 1.2rem;
  }
  .search-keyword {
    color: @em-color;
    font-weight: bold;
  }
}

.archives {
  .archive-year-wrap {
    font-size: 1.5rem;
    line-height: 1.5rem;
    @media screen and (min-width: 476px) {
      margin-left: -1rem;
    }
  }
  .archive {
    border-left: 2px solid #ccc;
    position: relative;
    padding: 0.5rem 0;
    padding-left: 1rem !important;
    &:before {
      position: absolute;
      top: 1rem;
      left: -7px;
      z-index: 1;
      display: block;
      content: "";
      background-color: #999;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      border: 2px solid #fff;
    }
    .title {
      margin: 0;
      font-weight: normal;
    }
    .date {
      color: #666;
      font-family: "Montserrat", sans-serif;
      text-transform: uppercase;
      font-size: 0.7rem;
      font-weight: 300;
      letter-spacing: 2px;
      @media screen and (min-width: 476px) {
        position: absolute;
        right: 0;
        top: 0.5em;
      }
    }
  }
}

.footer {
  .footer-link {
    &:after {
      content: "|";
      display: inline;
      margin: 0 5px;
    }

    &:last-child {
      &:after {
        display: none;
      }
    }
  }
}

.photos {
  .photo {
    margin-bottom: 12px;
  }
}

.video-container {
  width: 100%;
  height: 400px;
}
